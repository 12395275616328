import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import getConfig from "next/config";
import { useEffect } from "react";
import smoothscroll from "smoothscroll-polyfill";
import { setLastVisit } from "utils/tracking";
import HarmonyMenu from "HarmonyMenu/harmonyMenu";

if (process.browser) {
  require("webp-in-css/polyfill");
}

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NODE_ENV,
  });
}

export default function App({ Component, pageProps, err }) {
  useEffect(() => {
    setLastVisit();
  }, []);

  // Workaround for https://github.com/vercel/next.js/issues/8592
  if (typeof window !== "undefined") {
    smoothscroll.polyfill();
  }

  return (
    <>
      <HarmonyMenu />
      <Component {...pageProps} err={err} />
    </>
  );
}
