import { useEffect, useState } from "react";
import { useRouter } from "next/router";

export const useHarmonyEvents = () => {
  const router = useRouter();
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    if (!window.HarmonyEventManager) {
      if (attempts < 3) {
        setAttempts((prevAttempts) => prevAttempts + 1);
        setTimeout(() => {
          // Force a re-render to run the useEffect callback again
          setAttempts(attempts);
        }, 500);
      }
      return;
    }

    const EventManager = window.HarmonyEventManager;

    const handleLinkClick = (event) => {
      router.push(event.detail);
    };

    const handleMouseEnter = (event) => {
      console.log("prefetching", event.detail);
      router.prefetch(event.detail);
    };

    EventManager.on(EventManager.EVENTS.LINK_CLICK, handleLinkClick);
    EventManager.on(EventManager.EVENTS.LINK_MOUSE_ENTER, handleMouseEnter);

    return () => {
      EventManager.off(EventManager.EVENTS.LINK_CLICK, handleLinkClick);
      EventManager.off(EventManager.EVENTS.LINK_MOUSE_ENTER, handleMouseEnter);
    };
  }, [attempts]);
};

export const useMenuEvents = (callback) => {
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    if (!window.HarmonyEventManager) {
      if (attempts < 3) {
        setAttempts((prevAttempts) => prevAttempts + 1);
        setTimeout(() => {
          // Force a re-render to run the useEffect callback again
          setAttempts(attempts);
        }, 500);
      }
      return;
    }
    const EventManager = window.HarmonyEventManager;

    callback(EventManager);
  }, [attempts]);
};
