import { useEffect } from "react";
import { useHarmonyEvents } from "./EventManager";
function HarmonyMenu({ title }) {
  useHarmonyEvents();

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://harmonylabs-org-menu.netlify.app/menu-component.js";
    script.async = true;
    script.setAttribute("type", "module");

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const navData = {
    URL: "/",
    Title: "Poverty Narratives @NarrativeObservatory",
    Description:
      "Our model of narrative starts with audience and ends with stories that can shape the future.",
    CTA: {
      Text: "Explore the Narrative Observatory",
      URL: "https://narrativeobservatory.org/",
      OpenNewTab: true,
    },
    Links: [
      {
        Text: "Capital & Labor",
        URL: "/narratives/capital-and-labor/",
        OpenNewTab: false,
      },
      {
        Text: "Robin Hood",
        URL: "/narratives/robin-hood/",
        OpenNewTab: false,
      },
      {
        Text: "Vote for X",
        URL: "/narratives/vote-for-x/",
        OpenNewTab: false,
      },
      {
        Text: "Safety Net",
        URL: "/narratives/safety-net/",
        OpenNewTab: false,
      },
      {
        Text: "The Grind",
        URL: "/narratives/the-grind/",
        OpenNewTab: false,
      },
      {
        Text: "Not Like Us",
        URL: "/narratives/not-like-us/",
        OpenNewTab: false,
      },
      {
        Text: "Equity Gap",
        URL: "/narratives/equity-gap/",
        OpenNewTab: false,
      },
      {
        Text: "Helping Hands",
        URL: "/narratives/helping-hands/",
        OpenNewTab: false,
      },
    ],
    Footer: "latestNews",
  };

  return (
    <menu-component
      title={"Poverty Histographs"}
      home={false}
      closed={true}
      navID={1}
    />
  );
}

export default HarmonyMenu;
